import { Layout } from '../components/Layout';
import { login } from '../Twitch/login'
import { Twitch } from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button';
import { TwitchLogins } from '../LocalStore/TwitchLogins';

export function Main() {
  const primaryLogin = TwitchLogins.primaryLogin;
  if (primaryLogin) {
    return  <Layout />;
  } else {
    return (
      <div className='plamper-login'>
        <h1>Plamper Life 2 (beta)</h1>
        <Button onClick={login} size='lg'>
          <Twitch />&nbsp;Login to Twitch
        </Button>
      </div>
    );
  }
}