import './header.css';
import { useState, useEffect } from 'react';
import { HelixStream } from '@twurple/api';
import { getUptime } from '../../helpers';
import { Twitch } from '../../Twitch';
import {
  Clock,
  Person,
} from 'react-bootstrap-icons';
import { StreamSettings } from '../../LocalStore/StreamSettings';

export function Header(props: {
  stream: HelixStream;
}) {
  const { stream } = props;
  const [ followers, setFollowers ] = useState(0);
  const [ isMod, setIsMod ] = useState(false);

  const updateFollowerCount = async () => {
    const followers = await Twitch.instance.getFollowerCount(stream.userId);
    setIsMod(StreamSettings.isMod(stream.userId));
    setFollowers(followers);
  };

  useEffect(() => { updateFollowerCount() });

  const uptime = getUptime(stream.startDate);
  const followerCount = (followers) ? <>&nbsp;({followers} followers)</> : <></>;

  return (
    <div className='plamper-stream-header'>
      <div className='plamper-stream-header-left'>
        <a href={`https://twitch.tv/${isMod ? 'moderator/' : ''}${stream.userDisplayName}`} target='_blank'>
          {stream.userDisplayName.toUpperCase()}
        </a>&nbsp;
        {followerCount}
        { isMod ? <>&nbsp;(Moderator)</> : <></>}
      </div>
      <div className='plamper-stream-header-right'>
        <Person />&nbsp;{stream.viewers}
        &nbsp;/&nbsp;<Clock />&nbsp;{uptime}
      </div>
    </div>
  );
}
