import { Twitch } from '.';
import { HelixFollows, HelixStreams } from '../types';
import { log } from '../components/Debug';

export async function getLiveStreams(this: Twitch): Promise<HelixStreams> {
  if (!this.tokenInfo.userId) throw new Error(`getLiveStreams: Not logged into Twitch`);
  const request = this.api.streams.getFollowedStreamsPaginated(this.tokenInfo.userId);
  const helixStreams = await request.getAll();
  return helixStreams;
}

/**
 * The number of followers a user has.
 * @param userid
 * @returns
 */
export async function getFollowerCount(this: Twitch, userid: string): Promise<number> {
  if (!this.tokenInfo.userId) throw new Error(`getFollowing: Not logged into Twitch`);
  const user = await this.api.users.getUserById(userid);
  if (!user) throw new Error(`getFollowerCount: Could not find user ${userid}`);
  const followedChannels = await user.getChannelFollowers();
  return followedChannels.total;
}

export async function getUserIdByName(this: Twitch, username: string) {
  if (!this.tokenInfo.userId) {
    log('Unexpected! Token info is undefined!');
    throw new Error(`getUserByName: Not logged into Twitch`);
  }
  const helixUser = await this.api.users.getUserByName(username);

  console.log(helixUser);
  if (!helixUser) {
    log(`Username ${username} doesn't exist on Twitch.`);
    return;
  };
  return helixUser.id;
}

/**
 * Get a list of users that the logged in user is following.
 * @param this
 * @returns
 */
export async function getFollowing(this: Twitch): Promise<HelixFollows> {
  if (!this.tokenInfo.userId) throw new Error(`getFollowing: Not logged into Twitch`);
  const userId = this.tokenInfo.userId;
  const user = await this.api.users.getUserById(userId);
  if (!user) throw new Error(`getFollowing: Could not find user.`);

  const followedChannels = await user.getFollowedChannels();
  // This should return a list of all the channels the user is following
  // so that it populates "Show Offline streams". But right now its broken.
  // console.log(followedChannels.data);
  return [];
}

export function getLiveStreamByUserId(this: Twitch, userid: string) {
  if (!this.tokenInfo.userId) throw new Error(`getLiveStreams: Not logged into Twitch`);
  return this.api.streams.getStreamByUserId(userid);
}