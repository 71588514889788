import './Debug.css';
import { DEBUG_MSG } from '../../PubSub';
import { TwitchLogins } from '../../LocalStore/TwitchLogins';
import { useState } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import moment from 'moment';
import PubSub from 'pubsub-js';

const DEBUG_USERS = ['kifi2024', 'mystixa', 'triodeofficial'];


export function Debug() {
  const [ msg, setMsg ] = useState('');
  const [ err, setErr ] = useState(false);

  let timeoutInt: NodeJS.Timeout | null;

  PubSub.subscribe(DEBUG_MSG, (msg: string, data: { msg: string, err: boolean }) => {
    setMsg(data.msg);
    setErr(data.err);
    if (timeoutInt) clearTimeout(timeoutInt);
    timeoutInt = setTimeout(() => {
      setMsg('');
    }, 5 * 1000);
  });

  // if (!DEBUG_USERS.includes(TwitchLogins.primaryLogin!.login)) return <></>;
  if (!msg) return <></>;

  return (
    <div className={'plamper-debug'}>
      <span className={err ? 'plamper-error' : ''}>
        <XCircle onClick={() => { setMsg('') }} />&nbsp;{msg}
      </span>
    </div>
  );
}

export function log(msg: string, delayMs = 0, isError = false) {
  setTimeout(() => {
    const now = moment().format('hh:mm.ss a')
    console.log(msg);
    PubSub.publish(DEBUG_MSG, { msg: `${msg}`, err: isError });
  }, delayMs);
}