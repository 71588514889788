import { useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { StreamTypes, Tag } from '../../types';
import { TagSettings } from '../../LocalStore/TagSettings';

const KEY_CODES = { comma: 188, enter: 13, tab: 9 };
const DELIMITERS = [ KEY_CODES.comma, KEY_CODES.enter, KEY_CODES.tab ];

export function TagEditor(props: { stream: StreamTypes }) {
  const { stream } = props;
  return (
    <div className='plamper-tags-row'>
      <hr />
      <TagField stream={stream}/>
    </div>
  );
}

function TagField(props: { stream: StreamTypes }) {
  const { stream } = props;

  const [ tags, setTags ] = useState<Tag[]>(TagSettings.byUserId(stream.userId).tags);
  const [ suggestions, setSuggestions ] = useState<Tag[]>(TagSettings.suggestions);

  const handleDelete = (i: number) => {
    setTags(TagSettings.byUserId(stream.userId).delete(i));
    setSuggestions(TagSettings.suggestions);
  };

  const handleAddition = (tag: Tag) => {
    setTags(TagSettings.byUserId(stream.userId).add(tag));
    setSuggestions(TagSettings.suggestions);
  };

  const handleInputIn = () => {
    setSuggestions(TagSettings.suggestions);
  }

  return (
    <ReactTags
      tags={tags}
      suggestions={suggestions}
      delimiters={DELIMITERS}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleInputFocus={handleInputIn}
      allowDragDrop={false}
      placeholder='Custom categories'
      allowUnique
      autocomplete
      autofocus={false}
      classNames={{
        tag: 'badge bg-secondary plamper-tag-wrapper',
        remove: 'badge bg-danger',
        tagInputField: 'form-control form-control-sm',
        suggestions: 'plamper-suggesions-list'
      }}
      renderSuggestion={
        (
          { text }, query) => (
            <div className='badge rounded-pill bg-dark'>{text}
            </div>
          )
      }
    />
  )
}