import _ from 'lodash';
import { Channel } from './Channel';
import { Form } from 'react-bootstrap';
import { HelixStream, HelixFollow } from '@twurple/api/lib';
import { Twitch } from '../../Twitch';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import PubSub from 'pubsub-js'
import React from 'react';
import { log } from '../Debug';
import { Badge } from 'react-bootstrap';
import { LiveChannels } from './LiveChannels';

import {
  TWITCH_API_CONNECTED,
  ADD_COMPONENT_BY_NAME,
  SEARCH_BOX_ERROR,
  PLAMPER_READY,
  LIVE_STREAM_UPDATE,
} from '../../PubSub';

import './FollowersList.css';

import { HelixStreams, HelixFollows } from '../../types';

interface State {
  helixStreams: HelixStreams;
  helixFollows: HelixFollows;
  isConnected: boolean;
  filter: string;
  searchError: string;
  showFollows: boolean;
}

export class FollowersList extends React.Component<{}, State> {

  private isConnected: boolean = false;
  private formControl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      helixStreams: [],
      helixFollows: [],
      isConnected: false,
      filter: '',
      searchError: '',
      showFollows: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.toggleShowFollows = this.toggleShowFollows.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe(TWITCH_API_CONNECTED, () => {
      this.isConnected = true;
      PubSub.publish(PLAMPER_READY);
    });

    PubSub.subscribe(SEARCH_BOX_ERROR, (msg: any, error: any) => {
      this.setState({ searchError: error });
    });

    // When Twitch refreshs streams, then update the lists.
    PubSub.subscribe(LIVE_STREAM_UPDATE, (msg: string) => {
      this.setState({
        helixStreams: Twitch.instance.liveStreams,
        helixFollows: Twitch.instance.followStreams
      });
    })
  }

  private handleChange(event: any) {
    this.setState({ filter: event.target.value});
  }

  private handleKeypress(key: string, event: KeyboardEvent) {
    event.preventDefault();
    if (key === 'esc') {
      this.setState({ filter: '', searchError: '' });
      this.formControl.value = '';
      this.formControl.blur();
    }
    if (key === 'enter') {
      const username = this.formControl.value;
      PubSub.publish(ADD_COMPONENT_BY_NAME, username);
      log(`Joining stream by name: ${username}`);
      this.setState({ filter: '', searchError: '' });
      this.formControl.value = '';
      this.formControl.blur();
    }
  }

  private toggleShowFollows() {
    this.setState({ showFollows: !this.state.showFollows });
  }

  render() {
    let { helixStreams: liveChannels, helixFollows: following, filter, searchError, showFollows } = this.state;

    if (!this.isConnected) return <><h2>Loading</h2></>

    if (filter) {
      const regex = new RegExp(`.*(${this.state.filter}).*`, 'ig');
      liveChannels = _.filter(liveChannels, (channel) => {
        return regex.test(channel.userName);
      });
      following = _.filter(following, (follow) => {
        return regex.test(follow.followedUserName);
      });
    }

    return (
      <div className='plamper-follow-list'>
        <Form>
          <KeyboardEventHandler
            handleKeys={['esc', 'enter']}
            onKeyEvent={this.handleKeypress}>
            <Form.Control
              ref={(ref: any) => {this.formControl = ref}}
              size='sm'
              type='text'
              placeholder={ searchError || 'Search or join any stream by name' }
              onChange={this.handleChange}
            />
          </KeyboardEventHandler>
        </Form>

        <LiveChannels channels={liveChannels} filter={filter} />

        <div className='plamper-followed-channels'>

          <div className='plamper-followed-channels-collapse'>
            <span className='plamper-channel' >
              <div></div>
              {/* <Badge className='plamper-info-button' onClick={this.toggleShowFollows}>{ showFollows ? 'Hide Offline Streams' : 'Show Offline Streams' }</Badge> */}
            </span>
          </div>

          { following.length && showFollows
            ? _.orderBy(following, 'followedUserName').map(follow =>
              <div key={follow.followedUserId}>
                <Channel stream={follow} />
              </div>)
            : <>{ filter ? '' : showFollows ? 'Loading followers ...' : '' }</> }
        </div>

      </div>
    )
  }
}
