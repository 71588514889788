import './Controls.css'
import './StreamPlayer.css';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Header } from './Header';
import { HelixStream } from '@twurple/api';
import { log } from '../Debug';
import { PlamperStreams } from '../../types';
import { StreamSettings } from '../../LocalStore/StreamSettings';
import { Tools } from './Tools';
import { Twitch } from '../../Twitch';
import { TwitchChat } from './TwitchChat';
import { TwitchVideo } from './TwitchVideo';
import { getAnalytics, logEvent } from "firebase/analytics";

import moment from 'moment';
import PubSub from 'pubsub-js';
import React from 'react';

import {
  REMOVE_COMPONENT,
  TOGGLE_SOLO,
  LIVE_STREAM_UPDATE,
  REPLACE_STREAM_PLAYER
} from '../../PubSub';

import {
  ArrowClockwise,
  Camera,
  VolumeUp,
  XCircle,
  ZoomIn,
} from 'react-bootstrap-icons';

export interface PlayerOptions {
  url: string;
  className: string;
  width?: string;
  height?: string;
  controls: boolean;
  playing: boolean;
  volume: number;
}

interface Props {
  userid: string;
  playOnLoad: boolean;
}

interface State {
  stream: HelixStream | null;
  playerOptions: PlayerOptions | null;
  muted: boolean;
  zoomed: boolean;
  hideVideo: boolean;
  keyEvent: boolean;
  hiQuality: boolean;
  refreshVideo: boolean;
}

export class StreamPlayer extends React.Component<Props, State> {

  private userid: string;
  private subscriptions!: string[];
  private internalPlayer!: any;

  private updateSubscription!: string;
  private muteChannel!: string;
  private pubSubReplaceChannel!: string;

  constructor(props: Props) {
    super(props);
    this.userid = props.userid;
    this.state = {
      stream: null,
      playerOptions: null,
      muted: true,
      zoomed: false,
      hideVideo: false,
      keyEvent: false,
      hiQuality: false,
      refreshVideo: false
    };
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
    this.close = this.close.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.toggleZoom = this.toggleZoom.bind(this);
    this.toggleHideVideo = this.toggleHideVideo.bind(this);
    this.refreshVideo = this.refreshVideo.bind(this);
  }

  componentDidMount() {
    this.updateSubscription = PubSub.subscribe(LIVE_STREAM_UPDATE, async (msg: string, streams: PlamperStreams) => {
      const stream = await Twitch.instance.getLiveStreamByUserId(this.userid);
      if (stream !== null) this.setState({ stream: stream });
    });
    this.muteChannel = PubSub.subscribe(TOGGLE_SOLO, (msg: string, userid: string) => {
      if (userid !== this.userid) {
        this.setState({ muted: true });
      }
    });

    this.pubSubReplaceChannel = PubSub.subscribe(REPLACE_STREAM_PLAYER, async (msg: string, targetUsername: string) => {
      const userid = await Twitch.instance.getUserIdByName(targetUsername);
      if (userid) {
        this.userid = userid;
        this.initStream();
      }
    });

    this.initStream();
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.updateSubscription);
    PubSub.unsubscribe(this.muteChannel);
  }

  private async initStream() {
    const { playOnLoad } = this.props;
    const helixStream = await Twitch.instance.getLiveStreamByUserId(this.userid);
    if (!helixStream) {
      log(`Could not load ${this.userid}`, 500);
      this.close();
      throw new Error(`Could not get helix stream ${this.userid}`);
    }

    const volume = (
      StreamSettings.isLurkMode ? 0
      : StreamSettings.getVolume(this.userid));

    const playerOptions = {
      className: 'plamper-video-player',
      url: `https://twitch.tv/${helixStream.userDisplayName}`,
      width: '100%',
      height: '100%',
      controls: false,
      playing: playOnLoad,
      volume: volume || 0.5,
    };

    this.setState({
      stream: helixStream,
      playerOptions: playerOptions
    });

  }

  private close() {
    PubSub.publish(REMOVE_COMPONENT, this.props.userid);
  }

  private handleVolumeChange(event: any) {
    const { playerOptions } = this.state;
    if (!playerOptions) throw new Error('Unexpected: Player options is undefined');
    const vol = event.target.value
    playerOptions.volume = parseFloat(vol);
    this.setState({ playerOptions: playerOptions });
    StreamSettings.setVolume(this.userid, playerOptions.volume);
  }

  private toggleMute(event?: any) {
    this.setState({ muted: !this.state.muted });
    if (event && !event.shiftKey) {
      this.logEvent('toggle_solo', { userid: this.userid });
      PubSub.publish(TOGGLE_SOLO, this.userid);
    } else {
      this.logEvent('toggle_shift_solo', { userid: this.userid });
    }
  }

  private toggleZoom() {
    this.logEvent('toggle_zoom', { userid: this.userid });
    this.setState({ zoomed: !this.state.zoomed });
  }

  private toggleHideVideo() {
    this.logEvent('toggle_video', { userid: this.userid });
    this.setState({ hideVideo: !this.state.hideVideo });
  }

  private logEvent(name: string, params: any) {
    const analytics = getAnalytics();
    logEvent(analytics, name, params);
  }

  private refreshVideo() {
    this.setState({ refreshVideo: true });
    setTimeout(() => {
      this.setState({ refreshVideo: false });
    }, 1000);
  }

  render() {
    const { playerOptions, stream, muted, zoomed, hideVideo, hiQuality, refreshVideo } = this.state;
    const { playOnLoad } = this.props;
    const displayName = stream ? stream.userDisplayName : 'Loading ...';

    console.log(`${moment().format('hh:mm:ss a')}: StreamPlayer update ${this.userid}`);

    return (
      <div className={`plamper-stream ${zoomed ? 'plamper-stream-zoom' : ''}`}>
        {/* HEADER */}
        { stream ? <Header stream={stream} /> : <></> }

        {/* TOOLS */}
        {/* <Tools userid={stream?.userId} /> */}

        {/* VIDEO */}
        <div
          className={`plamper-video-container ${zoomed ? 'plamper-video-container-zoom' : ''}`}
          hidden={hideVideo} >
          { playerOptions && !refreshVideo
            ?
              <TwitchVideo
                playerOptions={playerOptions}
                muted={muted}
                hiQuality={hiQuality}
              />
            : <></> }
        </div>

        {/* CONTROLS */}
        <Form>
          <div className='plamper-stream-controls'>
            <div>
              <div className={`btn btn-sm btn-${muted ? 'secondary' : 'success'}`} onClick={this.toggleMute}>
                <VolumeUp className='icon' />
              </div>
              &nbsp;

              { playerOptions
                ?
                  <input
                    type='range'
                    className='volume-slider'
                    min={0}
                    max={1}
                    value={playerOptions.volume}
                    onChange={this.handleVolumeChange}
                    step='any'
                  ></input>
                : <></>
              }
            </div>
            <div className='plamper-stream-controls'>
              <Button size='sm' onClick={this.refreshVideo} variant='secondary'>
                <ArrowClockwise className='icon' />
              </Button>
              &nbsp;
              <ButtonGroup>
                {/* <Button size='sm' variant='secondary'>HD</Button> */}
                <Button size='sm' variant='secondary' onClick={this.toggleZoom}><ZoomIn className='icon' /></Button>
                <Button size='sm' variant='secondary' onClick={this.toggleHideVideo}><Camera className='icon' /></Button>
              </ButtonGroup>
              &nbsp;
              <Button size='sm' onClick={this.close} variant='danger'>
                <XCircle className='icon' />
              </Button>
            </div>
          </div>
        </Form>

        {/* CHAT */}
        { stream ? <TwitchChat displayName={displayName} /> : <></> }

      </div>
    )
  }
}