import { Twitch } from '../Twitch';
import { StreamSettings } from './StreamSettings';
import { log } from '../components/Debug';

export async function upgradeSettings() {

  // if (localStorage.getItem('stayLoggedIn')) {}
  // if (localStorage.getItem('largerZoom')) {}
  // if (localStorage.getItem('lightMode')) {}
  // if (localStorage.getItem('userTracking')) {}
  // if (localStorage.getItem('hideAndPause')) {}

  // if (localStorage.getItem('volume')) {
  //   const item = localStorage.getItem('volume');
  //   if (!item) return;
  //   const settings = JSON.parse(item);
  //   for (const displayName of Object.keys(settings)) {
  //     const userid = await Twitch.instance.getUserIdByName(displayName);
  //     const volume = settings[displayName];
  //     if (userid) {
  //       log(`Copied volume settings from Plamper 1.0`);
  //       StreamSettings.setVolume(userid, volume);
  //     }
  //   }
  // }

  // if (localStorage.getItem('autoJoin')) {
  //   const item = localStorage.getItem('autoJoin');
  //   if (!item) return;
  //   const settings = JSON.parse(item);
  //   for (const displayName of Object.keys(settings)) {
  //     const userid = await Twitch.instance.getUserIdByName(displayName);
  //     const autojoin = settings[displayName];
  //     if (userid) {
  //       log(`Copied auto join settings from Plamper 1.0`);
  //       StreamSettings.setAutoJoin(userid, autojoin);
  //     }
  //   }
  // }
}