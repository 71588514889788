import _ from 'lodash';
import { authProvider } from './authProvider';
import { ChatClient } from '@twurple/chat';
import { getAnalytics, setUserId } from "firebase/analytics";
import { HelixFollows, HelixStreams } from '../types';
import { log } from '../components/Debug';
import { sleep } from '../helpers';
import { StreamSettings } from '../LocalStore/StreamSettings';
import { TokenInfo } from '@twurple/auth/lib';
import { upgradeSettings } from '../LocalStore/upgradeSettings';
import * as Topics from '../PubSub'
import PubSub from 'pubsub-js';

import {
  getFollowerCount,
  getFollowing,
  getLiveStreamByUserId,
  getLiveStreams,
  getUserIdByName,
} from './apiCalls';

import {
  ApiClient,
  HelixPrivilegedUser,
  HelixStream,
} from '@twurple/api';

import {
  BasicPubSubClient as TwitchBasicPubSubClient,
  PubSubClient as TwitchPubSubClient,
} from '@twurple/pubsub';

const NOT_LOGGED_IN = 'Not logged into Twitch';
const USER_UNDEFINED = 'Could not find user.';
const STREAMS_REFRESH_RATE_SEC = 30;

export class Twitch {

  public helixStreams: Map<string, HelixStream> = new Map();

  private _api!: ApiClient;
  private _chatClient!: ChatClient;
  private _basicTwitchPubSub!: TwitchBasicPubSubClient;
  private _self!: HelixPrivilegedUser;
  private _tokenInfo!: TokenInfo;
  private _twitchPubSub!: TwitchPubSubClient;
  private _prevStreams: string[] = [];
  private _updateInterval!: NodeJS.Timer;
  private _registeredListeners: Map<string, any> = new Map();

  private _authProvider: any;

  private _liveStreams!: HelixStreams;
  private _followStreams!: HelixFollows;

  public getLiveStreams = getLiveStreams;
  public getFollowerCount = getFollowerCount;
  public getUserIdByName = getUserIdByName;
  public getFollowing = getFollowing;
  public getLiveStreamByUserId = getLiveStreamByUserId;

  private static _instance: Twitch;
  static get instance(): Twitch {
    return this._instance || (this._instance = new this());
  }

  constructor() {
    this.getLiveStreams = this.getLiveStreams.bind(this);
    this.getLiveStreamByUserId = this.getLiveStreamByUserId.bind(this);
    this.getUserIdByName = this.getUserIdByName.bind(this);
    this.getFollowing = this.getFollowing.bind(this);
    this.joinOrLeaveStreams = this.joinOrLeaveStreams.bind(this);
    this.connect = this.connect.bind(this);
    PubSub.subscribe(Topics.TWITCH_API_CONNECT, this.connect);
  }

  get twitchPubSub() {
    return this._twitchPubSub;
  }

  get self() {
    if (!this._self) throw new Error(`self(): ${NOT_LOGGED_IN}`);
    return this._self;
  }

  async connect() {
    const provider = authProvider();
    this._authProvider = provider;
    if (!provider) {
      console.log('No auth provider');
      return;
    };

    // Get API client
    console.log('Getting API client');
    this._api = new ApiClient({ authProvider: provider });

    // Get API Token info
    console.log('Getting api token');
    this._tokenInfo = await this._api.getTokenInfo();
    // if (pubsubUserId !== this.tokenInfo.userId) throw new Error(`Pubsub and API userid doesn't match`);
    if (!this.tokenInfo.userId) throw new Error(`Unexpected: userid is blank`);
    if (!this.tokenInfo.userName) throw new Error(`Unexpected: userName is blank`);

    // chat client
    this._chatClient = new ChatClient({ authProvider: provider, channels: [this.tokenInfo.userName] });
    await this.chatClient.connect();

    const analytics = getAnalytics();
    setUserId(analytics, this.tokenInfo.userName);

    PubSub.subscribe(Topics.ADD_COMPONENT_BY_NAME, this.joinStreamByName.bind(this));
    PubSub.subscribe(Topics.PLAMPER_READY, this.onPlamperReady.bind(this));
    PubSub.subscribe(Topics.ADD_COMPONENT, this.onJoin.bind(this));
    PubSub.subscribe(Topics.REMOVE_COMPONENT, this.onLeave.bind(this));
    PubSub.subscribe(Topics.HOST_USER_ID, this.hostByUserId.bind(this));
    upgradeSettings();

    this.watchNewLiveStreams();

    PubSub.publish(Topics.TWITCH_API_CONNECTED);
    log('Plamper 2.0 started');
  }

  getDisplayNameByLoadedUserId(userid: string) {
    return _.find(this.liveStreams, { userId: userid })?.userDisplayName
      || _.find(this.followStreams, { followedUserId: userid })?.followedUserDisplayName
      || userid;
  }

  private async hostByUserId(msg: string, userid: string) {
    const user = await this.api.users.getUserById(userid);
    if (!user) {
      log(`Could not find user ${userid}`);
      return;
    }
    const cmd = `/host ${user.displayName}`;
    this.chatClient.say(this.username, cmd);
    console.log(cmd)
  }

  private async onJoin(msg: string, userid: string) {
    // Update the list of moderators
    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(userid);

    console.log(`joined ${displayName}`);

    this.chatClient.join(displayName);
    // const result = await this.chatClient.getMods(displayName);
    // StreamSettings.setMods(userid, result);
  }

  private async onLeave(msg: string, userid: string) {
    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(userid);
    this.chatClient.part(displayName);
  }

  async joinStreamByName(msg: any, username: string) {
    log(`Attempting to join stream by name ${username}`);
    const helixStream = await this.api.streams.getStreamByUserName(username);
    // this._manuallyAddedStreams.push(helixStreams);
    console.log(helixStream);
    if (helixStream) PubSub.publish(Topics.ADD_COMPONENT, helixStream.userId);
  }

  get api() {
    if (!this.tokenInfo.userId) throw new Error(`getLiveStreams: ${NOT_LOGGED_IN}`);
    return this._api;
  }

  get liveStreams() {
    return this._liveStreams;
  }

  get followStreams() {
    return this._followStreams;
  }

  get chatClient() {
    return this._chatClient;
  }

  //=======================================================================

  protected get tokenInfo() {
    return this._tokenInfo;
  }

  protected get userid() {
    if (!this.tokenInfo.userId) throw new Error(`getLiveStreams: ${NOT_LOGGED_IN}`);
    return this.tokenInfo.userId;
  }

  protected get username() {
    if (!this.tokenInfo.userName) throw new Error(`getLiveStreams: ${NOT_LOGGED_IN}`);
    return this.tokenInfo.userName;
  }

  //=======================================================================

  private watchNewLiveStreams() {
    this._updateInterval = setInterval(this.handleLiveStreams.bind(this), STREAMS_REFRESH_RATE_SEC * 1000);
  }

  private async onPlamperReady() {
    this._liveStreams = await this.getLiveStreams();
    const followStreams = await this.getFollowing();
    const liveChannels = this.liveStreams.map(channel => channel.userId);
    this._followStreams = _.filter(followStreams, (follow) => {
      return !liveChannels.includes(follow.followedUserId);
    });
    log(`Initialized live and followed streams.`);
    PubSub.publish(Topics.LIVE_STREAM_UPDATE);
    await this.joinOrLeaveStreams();
  }

  /**
   * Update live streams.
   */
  private async handleLiveStreams() {
    this._liveStreams = await this.getLiveStreams();
    const followStreams = await this.getFollowing();
    const liveChannels = this.liveStreams.map(channel => channel.userId);
    this._followStreams = _.filter(followStreams, (follow) => {
      return !liveChannels.includes(follow.followedUserId);
    });

    await this.joinOrLeaveStreams();
    PubSub.publish(Topics.LIVE_STREAM_UPDATE);
  }

  /**
   * Auto join or leave streams.
   */
  private async joinOrLeaveStreams() {
    const currentStreams = this.liveStreams.map(stream => stream.userId);
    const userIdsToAutoJoin = [];
    const userIdsToLeave = [];

    // Figure out when new streams come online
    const newStreamIds = _.difference(currentStreams, this._prevStreams);
    for (const streamId of newStreamIds) {
      // If the new stream is set to auto join, then add it to the list.
      if (StreamSettings.isAutoJoin(streamId)) {
        userIdsToAutoJoin.push(streamId);
      }
    }

    // Figure out which sterams stopped
    const endedStreamIds = _.difference(this._prevStreams, currentStreams);
    for (const streamId of endedStreamIds) {
      userIdsToLeave.push(streamId);
    }

    // Set the streams for next time.
    this._prevStreams = currentStreams;

    // Join stream if auto join is enabled.
    if (userIdsToAutoJoin.length) {
      for (const userid of userIdsToAutoJoin) {
        await sleep(1000);
        PubSub.publish(Topics.ADD_COMPONENT, userid);
      }
    }

    // Auto close streams
    if (userIdsToLeave.length) {
      for (const userid of userIdsToLeave) {
        await sleep(1000);
        PubSub.publish(Topics.REMOVE_COMPONENT, userid);
      }
    }
  }

}