import moment from 'moment';

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getUptime(createdAt: Date) {
  const upSince = new Date(createdAt);
  const duration = moment.duration(moment().diff(upSince));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const h = (hours < 10) ? `0${hours}` : `${hours}`;
  const m = (minutes < 10) ? `0${minutes}` : `${minutes}`;
  return `${h}:${m}`;
}

export function getFollowingSince(createdAt: Date) {
  const upSince = new Date(createdAt);
  const duration = moment.duration(moment().diff(upSince));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const y = (years === 0) ? '' : (years < 10) ? `${years}y ` : `${years}y `;
  const m = (months === 0) ? '' : (months < 10) ? `${months}mo ` : `${months}mo `;
  const d = (days < 10) ? `${days}d ` : `${days}d `;
  const h = (hours < 10) ? `${hours}h` : `${hours}h`;
  return `${y}${m}${d}${h}`;
}