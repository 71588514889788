import { LocalStoreSchema, LocalStoreSchemaKey } from '../types';
import { FirebaseDb } from '../Firebase';

export class LocalStore {

  static get get() {
    return new this().store;
  }

  static set(key: LocalStoreSchemaKey, value: any) {
    const localStore = new this();
    localStore.store[key] = value;
    localStore.write();
  }

  static getLoginName() {
    const store = new this().store;
    if (store.twitchLogins) {
      for (const logins in store.twitchLogins) {
        if (Object.prototype.hasOwnProperty.call(store.twitchLogins, logins)) {
          return store.twitchLogins[logins].login;
        }
      }
    }
  }

  constructor() {
    this.read();
  }

  private _store: LocalStoreSchema = {
    followers: [],
    streamSettings: {},
    twitchLogins: {},
    lurkMode: false
  };

  get store() {
    if (!this._store) throw new Error('Unexpected: could not read the internal store');
    return this._store;
  }



  write() {
    localStorage.setItem('store', JSON.stringify(this.store));

    for (const logins in this.store.twitchLogins) {
      if (Object.prototype.hasOwnProperty.call(this.store.twitchLogins, logins)) {
        const username = this.store.twitchLogins[logins].login;
        const settings = this.store;
        delete settings.twitchLogins;
        FirebaseDb.instance.updateCloudSettings(username, settings);
      }
    }
  }

  read() {
    const store = localStorage.getItem('store');
    if (!store) {
      this.write();
    } else {
      this._store = {
        ...this._store,
        ...JSON.parse(store)
      }
    }
  }


}