import _ from 'lodash';
import { LocalStore } from '.';
import { StreamSettings } from './StreamSettings';
import { Tag } from '../types';

export class TagSettings {

  private userid: string;
  tags: Tag[];

  static byUserId(userid: string) {
    return new this(userid);
  }

  /**
   * Get all the unique tags the user has already used.
   * @returns Tag suggestions
   */
  static get suggestions() {
    const settings = LocalStore.get.streamSettings;
    let suggestions: Tag[] = [];

    for (const userid in settings) {
      if (Object.prototype.hasOwnProperty.call(settings, userid)) {
        const userSettings = settings[userid];
        if (userSettings.tags)
          suggestions = [...suggestions, ...userSettings.tags];
      }
    }

    const output = _.uniqBy(suggestions, 'id');
    return output;
  }

  constructor(userid: string) {
    this.userid = userid;
    this.tags = this.getTags();
  }

  private getTags() {
    const settings = LocalStore.get.streamSettings;
    const streamSettings = settings[this.userid];
    if (!streamSettings) return [];
    if (streamSettings.tags) return streamSettings.tags;
    return [];
  }

  add(tag: Tag) {
    tag.id = tag.id.toLowerCase();
    tag.text = tag.id.toLowerCase();
    this.tags = [...this.tags, tag];
    StreamSettings.setTags(this.userid, this.tags);
    console.log('Tags added', this.tags);
    return this.tags;
  }

  delete(i: number) {
    this.tags = this.tags.filter((tag, index) => index !== i);
    StreamSettings.setTags(this.userid, this.tags);
    console.log('Tags deleted', this.tags);
    return this.tags;
  }

}