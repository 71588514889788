import './TwitchVideo.css';
import { PlayerOptions } from '.';
import React from 'react';
import ReactPlayer from 'react-player';
import { log } from '../Debug';

interface Props {
  playerOptions: PlayerOptions;
  muted?: boolean;
  hiQuality: boolean;
}

const MIN_VOL = 0.0001;

export class TwitchVideo extends React.Component<Props, {}> {

  private player: any;
  private internalPlayer: any;
  private firstPlay: boolean = true;

  constructor(props: Props) {
    super(props);
    this.ref = this.ref.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  private ref(player: any) {
    this.player = player;
  }

  private onPlay() {
    if (!this.firstPlay) return;
    const { hiQuality } = this.props;
    this.internalPlayer = this.player.getInternalPlayer();
    const qualities = this.internalPlayer.getQualities();
    let qSetting;
    if (!hiQuality) {
      if (qualities.length <= 1) {
        log('Only one video quality option available. Set video quality to auto.');
        qSetting = qualities[0];
      }
      if (qualities.length <= 2) {
        log('Two video options available. Setting to lowest.');
        qSetting = (qualities[qualities.length - 1]);
      }
      if (qualities.length > 2) {
        log('Setting to second lowest video quality.');
        qSetting = (qualities[qualities.length - 2]);
      }
    } else {
      qSetting = (qualities[0]);
    }

    log(`Set video quality to ${qSetting.name}: Bitrate = ${qSetting.bitrate}, ` +
      `size=${qSetting.width}x${qSetting.height}, fps=${qSetting.framerate}`);
    this.internalPlayer.setQuality(qSetting.name);
    this.firstPlay = false;
  }

  render() {
    const { playerOptions, muted } = this.props;

    // Set the volume to minumum if it's lower than the minimum
    // or set the volume to minimum if mute is on.
    if (playerOptions.volume <= MIN_VOL) playerOptions.volume = MIN_VOL;
    const vol = (muted ? MIN_VOL : playerOptions.volume);

    let options = {
      ...playerOptions,
      volume: vol,
      ref: this.ref,
      onPlay: this.onPlay
    };

    return (
      <>
        <ReactPlayer
          {...options}
        />
      </>
    )
  }
}

/*
[
    {
        "bitrate": 999999999,
        "codecs": "",
        "framerate": 60,
        "group": "auto",
        "height": 0,
        "isDefault": false,
        "name": "Auto",
        "width": 0
    },
    {
        "name": "720p60 (source)",
        "group": "chunked",
        "codecs": "avc1.640020,mp4a.40.2",
        "bitrate": 4001524,
        "width": 1280,
        "height": 720,
        "framerate": 60,
        "isDefault": true
    },
    {
        "name": "720p60",
        "group": "720p60",
        "codecs": "avc1.4D401F,mp4a.40.2",
        "bitrate": 3594742,
        "width": 1280,
        "height": 720,
        "framerate": 60,
        "isDefault": false
    },
    {
        "name": "480p",
        "group": "480p30",
        "codecs": "avc1.4D401F,mp4a.40.2",
        "bitrate": 1599742,
        "width": 852,
        "height": 480,
        "framerate": 30,
        "isDefault": false
    },
    {
        "name": "360p",
        "group": "360p30",
        "codecs": "avc1.4D401F,mp4a.40.2",
        "bitrate": 630000,
        "width": 640,
        "height": 360,
        "framerate": 30,
        "isDefault": false
    },
    {
        "name": "160p",
        "group": "160p30",
        "codecs": "avc1.4D401F,mp4a.40.2",
        "bitrate": 230000,
        "width": 284,
        "height": 160,
        "framerate": 30,
        "isDefault": false
    }
]
 */
