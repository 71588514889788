import React from 'react';
import { Badge } from 'react-bootstrap';

export function EditButton(props: any) {
  const { showEditCard, setShowEditCard } = props;
  return (
    <>
      <Badge
        className='plamper-info-button'
        onClick={() => setShowEditCard(!showEditCard)}
      >
        Edit
      </Badge>
    </>
  );
}
