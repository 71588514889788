import { LocalStore } from '.';
import { TStreamSettings, Tag } from '../types';
import { getAnalytics, logEvent } from "firebase/analytics";
import { log } from '../components/Debug';
import { Twitch } from '../Twitch';
import { TwitchLogins } from './TwitchLogins';

export class StreamSettings {

  static setVolume(userid: string, vol: number) {
    const streamSettings = LocalStore.get.streamSettings || {};
    const settings: TStreamSettings = {};
    settings[userid] = { ...streamSettings[userid], volume: vol };
    LocalStore.set('streamSettings', {
      ...streamSettings,
      ...settings
    });
  }

  static getVolume(userid: string) {
    const settings = LocalStore.get.streamSettings;
    if (!settings[userid]) return 0.5;
    return settings[userid].volume;
  }

  static getTags(userid: string) {
    return LocalStore.get.streamSettings[userid].tags || [];
  }

  static setTags(userid: string, tags: Tag[]) {
    const streamSettings = LocalStore.get.streamSettings || {};
    const settings: TStreamSettings = {};
    settings[userid] = { ...streamSettings[userid], tags: tags };
    LocalStore.set('streamSettings', {
      ...streamSettings,
      ...settings
    });
  }

  static isVip(userid: string) {
    const settings = LocalStore.get.streamSettings;
    if (!settings[userid]) return false;
    return settings[userid].vip || false;
  }

  static setVip(userid: string, vip: boolean) {
    const streamSettings = LocalStore.get.streamSettings || {};
    const settings: TStreamSettings = {};
    settings[userid] = { ...streamSettings[userid], vip: vip };
    LocalStore.set('streamSettings', {
      ...streamSettings,
      ...settings
    });
  }

  static setMods(broadcasterUserid: string, modUserids: string[]) {
    const streamSettings = LocalStore.get.streamSettings || {};
    const settings: TStreamSettings = {};
    settings[broadcasterUserid] = { ...streamSettings[broadcasterUserid], modUserids: modUserids };
    LocalStore.set('streamSettings', {
      ...streamSettings,
      ...settings
    });
    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(broadcasterUserid);
    log(`Updated moderator list for ${displayName}`);
  }

  static isMod(broadcasterid: string) {
    const settings = LocalStore.get.streamSettings;
    if (!settings[broadcasterid]) return false;
    const username = TwitchLogins.primaryLogin?.login;
    if (username && settings[broadcasterid] && settings[broadcasterid].modUserids) {
      return settings[broadcasterid].modUserids.includes(username);
    }
    return false;
  }

  static setAutoJoin(userid: string, autojoin: boolean) {
    const analytics = getAnalytics();
    logEvent(analytics, 'set_auto_join', {
      userid: userid,
    });

    const streamSettings = LocalStore.get.streamSettings || {};
    const settings: TStreamSettings = {};
    settings[userid] = { ...streamSettings[userid], autojoin: autojoin };
    LocalStore.set('streamSettings', {
      ...streamSettings,
      ...settings
    });

    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(userid);
    log(`${autojoin ? 'Enabled' : 'Disabled'} auto join ${displayName} (${userid})`);
  }

  static isAutoJoin(userid: string) {
    const settings = LocalStore.get.streamSettings;
    if (!settings[userid]) return false;
    return settings[userid].autojoin;
  }

  static get isLurkMode() {
    return LocalStore.get.lurkMode;
  }

}
