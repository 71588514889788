export const ADD_COMPONENT = Symbol('ADD_COMPONENT');
export const ADD_COMPONENT_BY_NAME = Symbol('ADD_COMPONENT_BY_NAME');
export const DEBUG_MSG = Symbol('DEBUG_MSG');
export const REMOVE_COMPONENT = Symbol('REMOVE_COMPONENT');
export const SEARCH_BOX_ERROR = Symbol('SEARCH_BOX_ERROR');
export const TOGGLE_SOLO = Symbol('TOGGLE_SOLO');
export const TWITCH_API_CONNECT = Symbol('TWITCH_API_CONNECT');
export const TWITCH_API_CONNECTED = Symbol('TWITCH_API_CONNECTED');
export const PLAMPER_READY = Symbol('PLAMPER_READY');
export const LIVE_STREAM_UPDATE = Symbol('LIVE_STREAM_UPDATE');
export const HOST_USER_ID = Symbol('HOST_USER_ID');
export const REPLACE_STREAM_PLAYER = Symbol('REPLACE_STREAM_PLAYER');
export const UPDATE_TAGS = Symbol('UPDATE_TAGS');