import { ADD_COMPONENT } from '../../PubSub';
import { StreamTypes, isHelixStream } from '../../types';
import { StreamSettings } from '../../LocalStore/StreamSettings';
import { Twitch } from 'react-bootstrap-icons';
import { ArrowUpRightSquare } from 'react-bootstrap-icons';

export function UserName(props: { stream: StreamTypes, useExternalLink?: boolean }) {
  const { stream, useExternalLink } = props;
  let options = {}, displayName = '', userid = '';

  if (isHelixStream(stream)) {
    displayName = stream.userDisplayName;
    userid = stream.userId;

    if (!useExternalLink) {
      options = {
        className: 'plamper-display-name-online',
        href: `#${displayName}`,
        onClick: () => { PubSub.publish(ADD_COMPONENT, stream.userId) }
      };

    } else {
      options = {
        className: 'plamper-display-name-online',
        href: `http://twitch.tv/${displayName}`,
        target: '_blank'
      };
    }

  } else {
    displayName = stream.followedUserDisplayName;
    userid = stream.followedUserId;

    options = {
      className: 'plamper-display-name-offline',
      href: `http://twitch.tv/${stream.followedUserName}`,
      target: '_blank'
    };
  }

  return (
    <>
      <a {...options}>
        <AutoJoin userid={userid} />
        <DisplayName displayName={displayName} />
        { useExternalLink ? <>&nbsp;<ArrowUpRightSquare /></> : <></> }
      </a>
    </>
  )
}

//============================================================================

function DisplayName(props: { displayName: string }) {
  let displayName = props.displayName;
  if (props.displayName.toLowerCase() === 'triodeofficial') {
    displayName = `❤️ ${displayName} ❤️`
  }
  return (
    <>{displayName}</>
  )
}

function AutoJoin(props: { userid: string }) {
  return (
    StreamSettings.isAutoJoin(props.userid)
      ? <><Twitch size='14px' className='plamper-autojoin-on'/>&nbsp;</>
      : <></>
  );
}
