import { StreamTypes } from '../../types';

interface Tag {
  id: string,
  text: string
};

export function Tags(props: { stream: StreamTypes }) {
  return (
    <></>
  )
}