import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Twitch } from './Twitch';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { FirebaseDb } from './Firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBnKEDMsW9EVhyZ-h1g7pRM-nPIBxW03Qg",
  authDomain: "plamper-life-2.firebaseapp.com",
  projectId: "plamper-life-2",
  storageBucket: "plamper-life-2.appspot.com",
  messagingSenderId: "386655836158",
  appId: "1:386655836158:web:f01c0ba828814569e1d2af",
  measurementId: "G-ENCHP8J6DM"
};

(async () => {
  const app = initializeApp(firebaseConfig);
  FirebaseDb.instance.init(app);
  const analytics = getAnalytics(app);

  await FirebaseDb.instance.getCloudSettings();

  console.log('[Plamper] Plamper Life 2.0 by Triode: https://twitch.tv/triodeofficial');
  const twitch = Twitch.instance;

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
