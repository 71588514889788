import { StaticAuthProvider } from '@twurple/auth';
import { CLIENT_ID, SCOPES } from './credentials';
import { TwitchLogins } from '../LocalStore/TwitchLogins';
import { login } from './login';
import _ from 'lodash';

export function authProvider(): StaticAuthProvider {
  const primaryLogin = TwitchLogins.primaryLogin;
  if (primaryLogin) {

    // Redo login if scopes have changed.
    if (_.difference(SCOPES, primaryLogin.scopes).length
      || _.difference(primaryLogin.scopes, SCOPES).length) {
        console.log('Scope has changed! Need to re-authorize user.');
        login();
      }

    return new StaticAuthProvider(CLIENT_ID, primaryLogin.accessToken);
  }
  throw new Error('No login available');
}