import React, { useState } from 'react';
import { StreamSettings } from '../../LocalStore/StreamSettings';
import { Toggle2On, Toggle2Off } from 'react-bootstrap-icons';
import { StreamTypes, isHelixStream } from '../../types';
import { Twitch } from 'react-bootstrap-icons';
import { getAnalytics, logEvent } from "firebase/analytics";

const SIZE = '30px';

export function AutoJoinSwitch(props: { stream: StreamTypes }) {
  const { stream } = props;
  const userid = isHelixStream(stream) ? stream.userId : stream.followedUserId;

  const [ isAutoJoin, setAutoJoin ] = useState(StreamSettings.isAutoJoin(userid));

  // Set the state and save the autojoin setting
  const saveAutoJoin = (autoJoin: boolean) => {

    const analytics = getAnalytics();
    logEvent(analytics, 'add_stream', {
      userid: userid
    });

    StreamSettings.setAutoJoin(userid, autoJoin);
    setAutoJoin(autoJoin);
  }

  return (
    <div className='plamper-options-row'>
      <div>Auto join when live</div>
      <div>
        <span className='plamper-autojoin-switch'>
          { isAutoJoin
              ?
                <Toggle2On size={SIZE}
                  onClick={() => saveAutoJoin(false)}
                  className='plamper-autojoin-switch-on'
                />
              :
                <Toggle2Off size={SIZE}
                  onClick={() => saveAutoJoin(true)}
                  className='plamper-autojoin-switch-off'
                />
          }
        </span>
      </div>
    </div>
  )
}
