import _ from 'lodash';
import './Layout.css';
import { ArrowUpLeftSquare } from 'react-bootstrap-icons';
import { Debug } from '../Debug';
import { FollowersList } from '../FollowersList';
import { getAnalytics, logEvent } from "firebase/analytics";
import { log } from '../Debug';
import { StreamPlayer } from '../StreamPlayer';
import { Twitch } from '../../Twitch';
import { TwitchLogin } from '../TwitchLogin';
import buildInfo from '../../buildInfo.json';
import PubSub from 'pubsub-js';
import React from 'react';

import {
  ADD_COMPONENT,
  REMOVE_COMPONENT,
  TWITCH_API_CONNECT
} from '../../PubSub';

type Container = {
  userid: string;
};

interface State {
  streams: Container[];
  hideLeftNav: boolean;
}

export class Layout extends React.Component<{}, State> {

  constructor(props: any) {
    super(props);
    this.state = { streams: [], hideLeftNav: false };
    this.addStream = this.addStream.bind(this);
    this.toggleLeftNav = this.toggleLeftNav.bind(this);
  }

  componentDidMount() {
    PubSub.publish(TWITCH_API_CONNECT);
    PubSub.subscribe(ADD_COMPONENT, this.addStream);
    PubSub.subscribe(REMOVE_COMPONENT, this.removeStream.bind(this));
  }

  private addStream(message: string, userid: string) {
    const { streams } = this.state;
    const exists = _.find(streams, { userid: userid });
    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(userid);
    if (exists) {
      log(`Channel ${displayName} ${userid} already loaded. Not loading again.`)
      return;
    };
    streams.push({ userid: userid });

    const analytics = getAnalytics();
    logEvent(analytics, 'add_stream', {
      userid: userid
    });

    this.setState({ streams: streams });
    log(`Added stream ${displayName} ${userid}`);

    if (this.tooManyStreamsOpen) {
      log('Disabled play on load because there are 4 running live streams.', 2000)
    }
  }

  get tooManyStreamsOpen() {
    const { streams } = this.state;
    return streams.length < 4;
  }

  /**
   * Remove a stream by it's id. This might get called when someone goes
   * offline, even if we're not watching that stream.
   *
   * @param message
   * @param userid
   */
  private removeStream(message: string, userid: string) {
    let { streams } = this.state;

    const lastStreamCount = streams.length;
    // Filter *out* the stream that we're looking for. Leaving streams that we want to stay in.
    streams = _.filter(streams, (stream) => { return stream.userid !== userid });

    // If we didn't remove any streams from the layout, do nothing.
    if (streams.length === lastStreamCount) return;

    const displayName = Twitch.instance.getDisplayNameByLoadedUserId(userid);
    this.setState({ streams: streams });
    log(`Closed stream ${displayName} ${userid}`);
  }

  private toggleLeftNav() {
    this.setState({ hideLeftNav: !this.state.hideLeftNav });
  }

  render() {
    const { hideLeftNav, streams } = this.state;
    const playOnLoad = (streams.length < 5);

    return (
      <div className='plamper-main-layout'>
        <div className='plamper-container'>
          <div className={`plamper-left-nav ${hideLeftNav ? 'plamper-left-nav-hide' : ''}`}>
            <span className='plamper-left-nav-toprow'>
              <a href='#' onClick={this.toggleLeftNav}><ArrowUpLeftSquare /></a>&nbsp;
              <TwitchLogin />
            </span>
            <FollowersList />
            <Debug />
            <div className='plamper-buildinfo'>
              { buildInfo.version }
            </div>
          </div>
          <div className='plamper-streams'>
            {
              _.map(streams, (stream) =>
                <StreamPlayer
                  key={stream.userid}
                  userid={stream.userid}
                  playOnLoad={playOnLoad}
                />
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
