import { TagSettings } from '../../LocalStore/TagSettings';
import { HelixStreams } from '../../types';
import { Channel } from './Channel';
import { useState } from 'react';
import _ from 'lodash';
import { StreamSettings } from '../../LocalStore/StreamSettings';

interface LiveChannelsOptions {
  channels: HelixStreams;
  filter: string;
}

export function LiveChannels(props: LiveChannelsOptions) {
  const { channels, filter } = props;

  const liveChannels = channels.length
    ? ChannelList(channels)
    : <NoChannels filter={filter} />

  return (
    <div className='plamper-live-channels'>
      {liveChannels}
    </div>
  )
}

function ChannelList(channels: HelixStreams) {
  const [refresh, setRefresh] = useState(0);

  let output: JSX.Element[] = [];
  const channelGroups = channels.map(channel => {
    const usertags = TagSettings.byUserId(channel.userId).tags;
    const isVip = StreamSettings.isVip(channel.userId);

    let group = '';

    if (isVip) {
      group = `VIP`
    } else {
      group = (usertags.length)
      ? usertags.map(tag => `[${tag.text}]`).sort().join(' ')
      : channel.gameName;
    }

    return {
      channel: channel,
      group: group,
      priority: isVip ? 0 : usertags.length ? 1 : 2,
      isCustom: !!usertags.length
    }
  });

  const groups = _.groupBy(_.sortBy(channelGroups, ['priority', 'group']), 'group');

  for (const groupName in groups) {
    if (Object.prototype.hasOwnProperty.call(groups, groupName)) {
      const group = groups[groupName];
      const priority = group[0].priority;
      const className =
        priority === 0 ? 'plamper-category-vip'
        : priority === 1 ? 'plamper-category-custom'
        : 'plamper-category-twitch';

      output.push(<div key={groupName} className={className}>{groupName}</div>);

      output = [...output, ...group.map(g =>
        <div key={g.channel.userId}>
          <Channel stream={g.channel} setRefresh={setRefresh} />
        </div>
      )];
    }
  }

  return output;
}

function NoChannels(props: { filter: string }) {
  return <> {props.filter ? '' : 'Loading Live channels ...'} </>
}