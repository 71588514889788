import { FirebaseApp } from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc, Firestore } from 'firebase/firestore';
import { LocalStoreSchema } from '../types';
import { LocalStore } from '../LocalStore';
import { log } from '../components/Debug';

export class FirebaseDb {

  private static _instance: FirebaseDb;
  private _db!: Firestore;

  static get instance(): FirebaseDb {
    return this._instance || (this._instance = new this());
  }

  init(app: FirebaseApp) {
    console.log('Initailized cloud settings');
    this._db = getFirestore(app);
  }

  get db() {
    if (!this._db) throw new Error('db not initialized');
    return this._db;
  }

  async getCloudSettings() {
    const loginName = LocalStore.getLoginName();
    if (loginName) {
      const settings = await this.getSettings(loginName);
      LocalStore.set('followers', settings?.followers);
      LocalStore.set('lurkMode', settings?.lurkMode);
      LocalStore.set('streamSettings', settings?.streamSettings);
    } else {
      console.log('Not getting cloud settings because plamper is not logged in');
    }
  }

  async updateCloudSettings(login: string, settings: LocalStoreSchema) {
    log(`Backed up settings to the cloud`, 2000);
    await setDoc(doc(this.db, `user-settings/${login}`), settings).catch(e => {
      console.error(e);
    });
  }

  async getSettings(login: string): Promise<LocalStoreSchema | undefined> {
    const snapshot = await getDoc(doc(this.db, `user-settings/${login}`));
    if (snapshot.exists()) {
      console.log(`Found cloud settings for ${login}`);
      const data = snapshot.data() as LocalStoreSchema;
      return data;
    }
  }

}
