import { StreamTypes, isHelixStream } from '../../types';
import React from 'react';

export function Viewers(props: { stream: StreamTypes; }) {
  const { stream } = props;
  if (isHelixStream(stream)) {
    return (
      <div className='plamper-live-stream-info'>
        {stream.viewers}
      </div>
    );
  } else {
    return <></>;
  }
}
