import { HelixStream, HelixFollow } from '@twurple/api';


export interface Follower {
  username: string;
  tags: string[];
};

export type Followers = Follower[];

//============================================================================

export interface LocalStoreSchema {
  followers: Followers;
  twitchLogins?: PlamperTwitchLogins;
  streamSettings: TStreamSettings;
  lurkMode: boolean;
};

export interface Tag {
  id: string;
  text: string;
};

export type LocalStoreSchemaKeys = {
  [K in keyof LocalStoreSchema as string extends K ? never : K]: LocalStoreSchema[K];
}
// this makes: type LocalStoreSchemaKey = 'twitchAccessToken' | 'anotherKey';
export type LocalStoreSchemaKey = keyof LocalStoreSchemaKeys;

//============================================================================

export interface TwurpleUser {
  creationDate: Date;
  description: string;
  displayName: string;
  email?: string;
  id: string;
  name: string;
  offlinePlaceholderUrl: string;
  profilePictureUrl: string;
  type: string;
  views: number;
};

//============================================================================

export interface PlamperLogin {
  // user?: TwurpleUser;
  clientId: string;
  expiresIn: number;
  login: string;
  scopes: string[];
  userId: string;
  accessToken: string;
  isPrimary: boolean;
};

export type PlamperTwitchLogins = {[accountName: string]: PlamperLogin};

//============================================================================

export interface StreamSetting {
  volume?: number;
  autojoin?: boolean;
  modUserids: string[];
  category?: string;
  tags?: Tag[];
  vip?: boolean;
}

export type TStreamSettings = { [userid: string]: StreamSetting };


export type StreamTypes = HelixStream | HelixFollow;

export function isHelixStream(stream: StreamTypes): stream is HelixStream {
  if ((stream as HelixStream).type) {
    return true;
  }
  return false;
}

export type HelixStreams = HelixStream[];
export type HelixFollows = HelixFollow[];

export type PlamperStreams = { helixStreams: HelixStreams, helixFollows: HelixFollows };