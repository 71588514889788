import React from 'react';
import { login } from '../../Twitch/login'
import Button from 'react-bootstrap/Button';
import { TwitchLogins } from '../../LocalStore/TwitchLogins';
import './TwitchLogin.css';
import { Badge } from 'react-bootstrap';
import { Twitch, ChatDots } from 'react-bootstrap-icons';

export function TwitchLogin(props: {
  buttonText?: string
}) {
  const { buttonText } = props;
  let loggedIn = false;

  let displayName;
  const primaryLogin = TwitchLogins.primaryLogin;

  if (primaryLogin) {
    displayName = primaryLogin.login;
    if (displayName === 'kifi2024') displayName = '❤️😘 kifi2024 😘❤️'
    loggedIn = true;
    const cookiesDisabled = !navigator.cookieEnabled;

    const openWhispers = () => {
      window.open(
        `https://www.twitch.tv/popout/moderator/${primaryLogin.login}/whispers`,
        'Whispers', 'height=700,width=600');
    };

    return (
      <div className='plamper-login'>
        {
          loggedIn
            ? <>
                <div className='plamper-login-displayname'>
                  <a href='#' onClick={login}>{displayName}</a>
                </div>
                <div>&nbsp;</div>
                <div>
                  <Badge onClick={openWhispers}><ChatDots /></Badge>
                </div>
              </>
            :
            <>
              <h1>Plamper Life 2 (beta)</h1>
              <Button onClick={login} size='lg'>
                <Twitch />&nbsp;Login to Twitch
              </Button>
            </>
        }
        { cookiesDisabled
          ? <div className='plamper-cookie-warn'>Please enable cookies for just plamper.life so it works!</div>
          : <></>
        }

      </div>
    );
  } else {
    return (
      <>
        <div className='plamper-login'>
          <h1>Plamper Life 2 (beta)</h1>
          <Button onClick={login} size='lg'>
            <Twitch />&nbsp;Login to Twitch
          </Button>
        </div>
      </>
    );
  }



}