import { LocalStore } from '.';
import { PlamperTwitchLogins, PlamperLogin } from '../types';
import _ from 'lodash';

export class TwitchLogins {

  static setTwitchAuth(userid: string, auth: any) {
    console.log('Storing auth for ', userid);
    const twitchLogins = LocalStore.get.twitchLogins || {};
    if (!twitchLogins) throw new Error(`Could not get user ${userid}`);
    const isPrimary = !!!Object.keys(twitchLogins).length;
    const logins: PlamperTwitchLogins = {};
    logins[userid] = { ...auth, isPrimary: isPrimary };
    LocalStore.set('twitchLogins', {
      ...twitchLogins,
      ...logins
    });
  }

  static get primaryLogin(): PlamperLogin | undefined {
    const twitchLogins = LocalStore.get.twitchLogins;
    for (const userid in twitchLogins) {
      if (Object.prototype.hasOwnProperty.call(twitchLogins, userid)) {
        const element = twitchLogins[userid];
        return element;
      }
    }
    return;
  }

  static getById(userid: string) {
    const twitchLogins = LocalStore.get.twitchLogins || {};
    return twitchLogins[userid];
  }

}