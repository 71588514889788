import React, { useState } from 'react';
import { StreamSettings } from '../../LocalStore/StreamSettings';
import { Toggle2On, Toggle2Off } from 'react-bootstrap-icons';
import { StreamTypes, isHelixStream } from '../../types';
import { Twitch } from 'react-bootstrap-icons';
import { getAnalytics, logEvent } from "firebase/analytics";

const SIZE = '30px';

export function VipSwitch(props: { stream: StreamTypes }) {
  const { stream } = props;
  const userid = isHelixStream(stream) ? stream.userId : stream.followedUserId;

  const [ isVip, setVip ] = useState(StreamSettings.isVip(userid));

  const saveVip = (vip: boolean) => {
    StreamSettings.setVip(userid, vip);
    setVip(vip);
  }

  return (
    <div className='plamper-options-row'>
      <div>Add to VIP</div>
      <div>
        <span className='plamper-autojoin-switch'>
          { isVip
              ?
                <Toggle2On size={SIZE}
                  onClick={() => saveVip(false)}
                  className='plamper-autojoin-switch-on'
                />
              :
                <Toggle2Off size={SIZE}
                  onClick={() => saveVip(true)}
                  className='plamper-autojoin-switch-off'
                />
          }
        </span>
      </div>
    </div>
  )
}
