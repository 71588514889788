import _ from 'lodash';
import './FollowersList.css';
import { getUptime, getFollowingSince } from '../../helpers';
import { StreamTypes, isHelixStream } from '../../types';
import { UserName } from './UserName';
import React, { useState } from 'react';

import {
  Badge,
  Collapse,
  OverlayTrigger,
  Popover,
  PopoverProps,
} from 'react-bootstrap';

import {
  FileMusicFill,
  Joystick
} from 'react-bootstrap-icons'

import { EditCard } from './EditCard';
import { EditButton } from './EditButton';
import { Viewers } from './Viewers';
import { Tags } from './Tags';


export function Channel(props: { stream: StreamTypes, setRefresh?: React.Dispatch<React.SetStateAction<number>> }) {
  const { stream, setRefresh } = props;

  const [ showEditButton, setShowEditButton ] = useState(false);
  const [ showEditCard, setShowEditCard ] = useState(false);

  // If for some reason we don't have a userid, viewers, or a display name
  // do not render anything.
  // if (!stream.userId) return <></>
  if (!stream.userDisplayName) return <></>;

  let overlay, iconType;

  if (isHelixStream(stream)) {
    const uptime = getUptime(stream.startDate);
    overlay = (
      <Popover show={true}>
        <Popover.Header as='h3'>
          {stream.title}
        </Popover.Header>
        <Popover.Body>
          <div>Game: {stream.gameName}</div>
          <div>Uptime: {uptime}</div>
          <div>Viewers: {stream.viewers}</div>
          <div>Type: {stream.type}</div>
        </Popover.Body>
      </Popover>
    );
    iconType = ( stream.gameName === 'Music' ? <FileMusicFill /> : <Joystick /> )
  } else {
    overlay = (
      <Popover show={true}>
        <Popover.Header as='h3'>
          {stream.followedUserDisplayName}
        </Popover.Header>
        <Popover.Body>
          <div>Following for: {getFollowingSince(stream.followDate)}</div>
        </Popover.Body>
      </Popover>
    );
    iconType = <></>;
  }

  return (
    <div>
      <OverlayTrigger
        key={stream.userId}
        placement='right'
        overlay={overlay}
        delay={{ show: 75, hide: 0}}
        onToggle={(show) => {
          setShowEditButton(show);
          if (!show && setRefresh) setRefresh(Math.random());
        }}
      >
        <span className='plamper-channel' >
          <div>
            <span className='plamper-display-name-online-icon'>{iconType}&nbsp;</span>
            <UserName stream={stream} /><Tags stream={stream} />
          </div>
          {( showEditButton || showEditCard )
            ? <EditButton
              showEditCard={showEditCard}
              setShowEditCard={setShowEditCard}
              />
            : <Viewers stream={stream} />
          }
        </span>
      </OverlayTrigger>
      <EditCard stream={stream} show={showEditCard} />

    </div>
  )
}
