import { createCacheKey } from "../utils/createCacheKey.mjs";
export function Cacheable(cls) {
    return class extends cls {
        constructor() {
            super(...arguments);
            this.cache = new Map();
        }
        getFromCache(cacheKey) {
            this._cleanCache();
            if (this.cache.has(cacheKey)) {
                const entry = this.cache.get(cacheKey);
                if (entry) {
                    return entry.value;
                }
            }
            return undefined;
        }
        setCache(cacheKey, value, timeInSeconds) {
            this.cache.set(cacheKey, {
                value,
                expires: Date.now() + timeInSeconds * 1000
            });
        }
        removeFromCache(cacheKey, prefix) {
            const internalCacheKey = this._getInternalCacheKey(cacheKey, prefix);
            if (prefix) {
                this.cache.forEach((val, key) => {
                    if (key.startsWith(internalCacheKey)) {
                        this.cache.delete(key);
                    }
                });
            }
            else {
                this.cache.delete(internalCacheKey);
            }
        }
        _cleanCache() {
            const now = Date.now();
            this.cache.forEach((val, key) => {
                if (val.expires < now) {
                    this.cache.delete(key);
                }
            });
        }
        _getInternalCacheKey(cacheKey, prefix) {
            if (typeof cacheKey === 'string') {
                let internalCacheKey = cacheKey;
                if (!internalCacheKey.endsWith('/')) {
                    internalCacheKey += '/';
                }
                return internalCacheKey;
            }
            else {
                const propName = cacheKey.shift();
                return createCacheKey(propName, cacheKey, prefix);
            }
        }
    };
}
