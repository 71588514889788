import { LocalStore } from '../LocalStore';
import { CLIENT_ID, SCOPES } from './credentials';

export function login() {
  const redirectUrl = `${window.location.origin}/callback`;
  window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=token&scope=${SCOPES.join(' ')}`;
}

// export function hasAccessToken() {
//   return !!LocalStore.get.twitchAccessToken;
// }
