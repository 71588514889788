import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import './App.css';
import { Main } from './Main'
import { AddAccount } from './AddAccount';
import { TwitchLogins } from './LocalStore/TwitchLogins';

function Callback() {
  const params = new URLSearchParams(window.location.hash.replace('#', ''));
  const accessToken = params.get('access_token');
  if (!accessToken) throw new Error('No access token');

  (async () => {
    console.log('accessToken', accessToken);

    const result = await fetch('https://id.twitch.tv/oauth2/validate', {
      headers: { Authorization: `OAuth ${accessToken}` }
    });

    const data = await result.json();

    TwitchLogins.setTwitchAuth(data.user_id, {
      clientId: data.client_id,
      expiresIn: data.expires_in,
      login: data.login,
      scopes: data.scopes,
      userId: data.user_id,
      accessToken: accessToken,
      isPrimary: true
    });

    setTimeout(() => {
      document.location.href = '/';
    }, 3000);

  })();

  return ( <><h2>Hold on.... logging you in ...</h2></> )
}

export default function App() {

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/callback' element={<Callback />} />
          <Route path='/addaccount' element={<AddAccount />} />
        </Routes>
      </Fragment>
    </Router>
  );
}
