import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { TwitchLogin } from '../components/TwitchLogin';
import { LocalStore } from '../LocalStore';

export function AddAccount() {
  const params = new URLSearchParams(window.location.hash.replace('#', ''));
  const accessToken = params.get('access_token') || '';

  if (accessToken) {


    const savedLogins = {
      ...LocalStore.get.twitchLogins
    }

    return (
      <>
        OK
      </>
    );
  } else {
    return (
      <>
        <TwitchLogin buttonText='Login with a different account'/>
      </>
    );
  }
}

function save() {

}