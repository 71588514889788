import { AutoJoinSwitch } from './AutoJoinSwitch';
import { Card } from 'react-bootstrap';
import { TagEditor } from './TagEditor';
import { StreamTypes } from '../../types';
import { UserName } from './UserName';
import { VipSwitch } from './VipSwitch';

export function EditCard(props: { stream: StreamTypes; show: boolean; }) {
  const { stream, show } = props;
  if (!show)
    return <></>;
  return (
    <Card bg='dark'>
      <Card.Header>
        <span className='plamper-editcard-header'>
          <UserName stream={stream} useExternalLink />
        </span>
      </Card.Header>
      <Card.Body>
        <div className='plamper-editcard-body'></div>
        <div className='plamper-editcard-optons'>
          <VipSwitch stream={stream} />
          <AutoJoinSwitch stream={stream} />
          <TagEditor stream={stream} />
        </div>
      </Card.Body>
    </Card>
  );
}
