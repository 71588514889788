
export function TwitchChat(props: {
  displayName: string
}) {
  const { displayName } = props;
  const baseUrl = 'https://www.twitch.tv/embed';
  const parent =
  process.env.NODE_ENV === 'development'
    ? 'localhost'
    : window.location.host;
  const suffixUrl = `chat?darkpopout=true&parent=${parent}`;

  return (
    <div className='plamper-chat-container'>
      <iframe
        className='plamper-chat-container'
        title={displayName}
        frameBorder={0}
        scrolling={'no'}
        src={`${baseUrl}/${displayName}/${suffixUrl}`}
      />
    </div>
  )
}
